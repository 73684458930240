<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <v-overlay :value="overlay">
            <v-progress-circular
                color="orange"
                indeterminate
                size="64"
                z-index="10000000000000000000000000000000000"
            ></v-progress-circular>
        </v-overlay>
        <v-row id="stats" justify="center" align="start" class="font-weight-bold mx-0 px-0">
            <v-col cols=1 align="center" class="mx-0 px-0 my-0">
                <v-row
                    v-for="player in team1SelectedPlayers"
                    :key="player.id"
                    class="mx-0 px-0 pb-6 mt-1"
                    @click="dialog=true; statDialog=true; currentTeam = 1; selectedPlayer = player;"
                    justify=center
                    align=center
                >
                    <v-col cols=12 class="mx-0 px-0 my-0 py-0" align="center">
                        <v-card
                            class="rounded-xl"
                            :style="`background: linear-gradient(110deg, #cd2021 30%, white 30%);`"
                        >
                            <div style="top: 0; left: 5px;">{{ `#${player.jersey}` }}</div>
                            <v-img
                                v-if="player.image"
                                :src="googleApiHost + '/' + player.image"
                                max-height="70px"
                                contain
                            />
                            <v-icon v-else style="font-size: 70px;" color="#cd2021"> mdi-account </v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols=6 class="mx-0 px-0 my-0 py-0" align="center">
                        <div>{{matchStats.team1.players.filter(element => element.jersey === player.jersey)[0].stats[0].points}}p</div>
                        <div>{{matchStats.team1.players.filter(element => element.jersey === player.jersey)[0].stats[0].rebounds}}r</div>
                    </v-col>
                    <v-col cols=6 class="mx-0 px-0 my-0 py-0" align="center">
                        <div>{{matchStats.team1.players.filter(element => element.jersey === player.jersey)[0].stats[0].assists}}a</div>
                        <div>{{matchStats.team1.players.filter(element => element.jersey === player.jersey)[0].stats[0].personalFouls}}f</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=3 align="center" class="mx-0 px-0">
                <v-row class="mx-0 px-0" align="center" justify=center>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('made'); category = '2pts'">2pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('made'); category = '3pts'">3pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('made'); category = '1pt'">1pt</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" align="center" justify=center>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('missed'); category = '2pts'">
                            <v-icon>mdi-cancel</v-icon>
                            2pt
                        </v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('missed'); category = '3pts'">
                            <v-icon>mdi-cancel</v-icon>
                            3pt
                        </v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#cd2021" @click="dialog=true; currentTeam = 1; setChoice('missed'); category = '1pt'">
                            <v-icon>mdi-cancel</v-icon>
                            1pt
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" align="center">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'dreb'">d. reb</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'oreb'">o. reb</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" align="center">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'to'">to</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'stl'">stl</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" align="center">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'asst'">asst</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; category = 'blk'">blk</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" align="center">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="playersDialog=true; currentTeam = 1">sub</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark large class="rounded-lg" color="#cd2021" @click="dialog=true; currentTeam = 1; foulsDialog = true; category = 'foul'">foul</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="match" cols=4 align="center" class="mx-0 px-0">
                <v-row class="font-weight-bold mx-0 px-0" align="center">
                    <v-col cols="12" class="mx-0 px-0 text-h6" align="center">
                        {{ `${ match.cup ? 'Κύπελλο' : match.league.title + ', ' + (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs')) }` }}
                    </v-col>
                </v-row>
                <v-row class="my-0 font-weight-bold mx-0 px-0" align="center">
                    <v-col cols="12" class="mx-0 px-0 text-h6" align="center">
                        {{ `Round ${match.round}, ${matchDate}` }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" justify=center align="center">
                    <v-col cols="5" align="center" class="mx-0 px-0 rounded-xl" style="background-color: #cd2021;">
                        <v-img width="50px" height="auto" :src="googleApiHost + '/' + match.team1.logo"/>
                        <span class="font-weight-bold white--text">{{ `${match.team1.name}` }}</span>
                    </v-col>
                    <v-col cols=1 align="center" class="mx-0 px-0 text-h6">
                        vs
                    </v-col>
                    <v-col cols="5" align="center" class="mx-0 px-0 rounded-xl" style="background-color: #1976d2;">
                        <v-img width="50px" height="auto" :src="googleApiHost + '/' + match.team2.logo"/>
                        <span class="font-weight-bold white--text">{{ `${match.team2.name}` }}</span>
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        <v-select style="width:100px; font-size: 20px;" label="Quarter" :items="[1,2,3,4,5,6,7,8,9,10]" v-model="quarter"/>
                    </v-col>
                    <v-col cols="6" class="font-weight-bold my-0 py-0 mx-0 px-0" style="font-size: 26px;" align="start">
                        <span class="mx-2" :style="`color: #cd2021`">{{ `${match.scoreTeam1}` }}</span>
                        <v-icon small>mdi-minus</v-icon>
                        <span class="mx-2" :style="`color: #1976d2`">{{ `${match.scoreTeam2}` }}</span>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'rebounds')[0].team1.rebounds }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Rebounds
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'rebounds')[0].team2.rebounds }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'steals')[0].team1.steals }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Steals
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'steals')[0].team2.steals }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'assists')[0].team1.assists }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Assists
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'assists')[0].team2.assists }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'turnovers')[0].team1.turnovers }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Turnovers
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'turnovers')[0].team2.turnovers }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'blockedShots')[0].team1.blockedShots }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Blocks
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'blockedShots')[0].team2.blockedShots }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'personalFouls')[0].team1.personalFouls }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        Fouls
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'personalFouls')[0].team2.personalFouls }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0 my-0 py-0" justify=center align="center">
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #cd2021;">
                        {{ stats.filter(element => element.value === 'technicalFouls')[0].team1.technicalFouls }}
                    </v-col>
                    <v-col cols="6" align="center" class="mx-0 px-0">
                        T.Fouls
                    </v-col>
                    <v-col cols="3" align="center" class="mx-0 px-0" style="color: #1976d2;">
                        {{ stats.filter(element => element.value === 'technicalFouls')[0].team2.technicalFouls }}
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mb-0 mx-5 px-0">
                    <v-col cols="12" class="match-timer py-0 mx-0 px-0 my-0" align="center">
                        <span style="display: inline-block;">
                            <v-btn icon large dark color="black" @click="switchTimer()">
                                <v-icon large dark>{{paused ? 'mdi-pause' : 'mdi-play'}}</v-icon>
                            </v-btn>
                        </span>
                        <span style="font-size: 20px; display: inline-block;" class="mx-5">
                            {{ `${minutes}:${seconds}` }}
                        </span>
                        <v-btn icon x-large color="black" @click="timerDialog = true">
                            <v-icon color="black">mdi-timer-edit-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn dark large color="error" @click="endMatchDialog = true">
                            End Match
                        </v-btn>
                        <v-dialog
                            v-model="endMatchDialog"
                            width="500"
                            hide-overlay
                            persistent
                            transition="dialog-bottom-transition"
                        >
                            <v-card dark class="py-5 px-5">
                                <v-row align=center justify=center>
                                    <v-col cols=12 align=center>
                                        <span class="font-weight-bold"> Are you sure? </span>
                                    </v-col>
                                    <v-col cols=6 align=end>
                                        <v-btn dark color="success" @click="endMatch()">
                                            yes
                                        </v-btn>
                                    </v-col>
                                    <v-col cols=6 align=start>
                                        <v-btn dark color="error" @click="endMatchDialog = false">
                                            no
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <div class="mt-16">
                        <v-card outlined class="py-2 rounded-lg" style="border: 2px solid black;" width="550px">
                            <div class="font-weight-bold text-center"> Feed: </div>
                            <v-virtual-scroll
                                :items="feed"
                                :item-height="50"
                                height="300"
                            >
                                <template v-slot:default="{ item }">
                                    <v-list-item style="font-size: 16px;">
                                        <span style="display: inline-flex;">{{ item.timestamp }}</span>
                                        <v-avatar class="mx-2 rounded-xl" style="display: inline-flex;" :style="item.teamId === team1? `background-color: #cd2021;` : `background-color: #1976d2;`">
                                            <v-img contain height="30px" :src="getTeamLogo(item)"/>
                                        </v-avatar>
                                        <span style="display: inline-flex;">{{ getLogText(item) }}</span>
                                        <v-icon
                                            v-if="item.playerMatchStatus === 'stop'"
                                            icon
                                            color="red"
                                            class="mx-2"
                                        >
                                            mdi-arrow-left
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.playerMatchStatus === 'begin'"
                                            icon
                                            color="green"
                                            class="mx-2"
                                        >
                                            mdi-arrow-right
                                        </v-icon>
                                        <v-spacer/>
                                        <v-list-item-action>
                                            <v-btn
                                                depressed
                                                icon
                                                small
                                                @click="deleteDialog = true; logId = item.logId"
                                            >
                                                <v-icon
                                                    color="error"
                                                >
                                                    mdi-trash-can
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                            <v-dialog
                                v-model="deleteDialog"
                                width="500"
                                hide-overlay
                                persistent
                                transition="dialog-bottom-transition"
                            >
                                <v-card dark class="py-5 px-5">
                                    <v-row align=center justify=center>
                                        <v-col cols=12 align=center>
                                            <span class="font-weight-bold"> Are you sure? </span>
                                        </v-col>
                                        <v-col cols=6 align=end>
                                            <v-btn dark color="success" @click="deleteLog()">
                                                yes
                                            </v-btn>
                                        </v-col>
                                        <v-col cols=6 align=start>
                                            <v-btn dark color="error" @click="deleteDialog = false">
                                                no
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-dialog>
                        </v-card>
                    </div>
                    <v-dialog
                        v-model="timerDialog"
                        width="500"
                        hide-overlay
                        persistent
                        transition="dialog-bottom-transition"
                    >
                        <v-card class="px-5 py-5">
                            <v-row align=center justify=center>
                                <v-col cols=5>
                                    <v-text-field
                                        v-model="newMinutes"
                                        type="number"
                                        label="enter minutes"
                                    />
                                </v-col>
                                <v-col cols=2 align=center>
                                    :
                                </v-col>
                                <v-col cols=5>
                                    <v-text-field
                                        v-model="newSeconds"
                                        type="number"
                                        label="enter seconds"
                                    />
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="timerDialog = false; changeTimer()"
                                >
                                    apply
                                </v-btn>
                                <v-btn
                                    color="error"
                                    text
                                    @click="timerDialog = false; newMinutes = null; newSeconds = null"
                                >
                                    close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
            <v-col cols=3 align="center" class="mx-0 px-0">
                <v-row class="mx-0 px-0" justify="center" align="center">
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('made'); category = '2pts'">2pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('made'); category = '3pts'">3pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('made'); category = '1pt'">1pt</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" justify="center" align="center">
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('missed'); category = '2pts'">
                            <v-icon>mdi-cancel</v-icon>
                            2pt
                        </v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('missed'); category = '3pts'">
                            <v-icon>mdi-cancel</v-icon>
                            3pt
                        </v-btn>
                    </v-col>
                    <v-col cols=4 align="center" class="px-0">
                        <v-btn width="95%" height="70px" fab dark rounded color="#1976d2" @click="dialog=true; currentTeam = 2; setChoice('missed'); category = '1pt'">
                            <v-icon>mdi-cancel</v-icon>
                            1pt
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'dreb'">d. reb</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'oreb'">o. reb</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'to'">to</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'stl'">stl</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'asst'">asst</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; category = 'blk'">blk</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="playersDialog=true; currentTeam = 2">sub</v-btn>
                    </v-col>
                    <v-col cols=6 align="center" class="px-0">
                        <v-btn width="95%" height="70px" dark class="rounded-lg" color="#1976d2" @click="dialog=true; currentTeam = 2; foulsDialog = true; category = 'foul'">foul</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=1 align="center" class="mx-0 px-0 my-0">
                <v-row
                    v-for="player in team2SelectedPlayers"
                    :key="player.id"
                    class="mx-0 px-0 pb-6 mt-1"
                    @click="dialog=true; statDialog=true; currentTeam = 2; selectedPlayer = player;"
                    justify=center
                    align=center
                >
                    <v-col cols=12 class="mx-0 px-0 my-0 py-0" align="center">
                        <v-card
                            class="rounded-xl"
                            :style="`background: linear-gradient(110deg, #1976d2 30%, white 30%);`"
                        >
                            <div style="top: 0; left: 5px;">{{ `#${player.jersey}` }}</div>
                            <v-img
                                v-if="player.image"
                                :src="googleApiHost + '/' + player.image"
                                max-height="70px"
                                contain
                            />
                            <v-icon v-else style="font-size: 70px;" color="#1976d2">
                                mdi-account
                            </v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols=6 class="mx-0 px-0 my-0 py-0" align="center">
                        <div>{{matchStats.team2.players.filter(element => element.jersey === player.jersey)[0].stats[0].points}}p</div>
                        <div>{{matchStats.team2.players.filter(element => element.jersey === player.jersey)[0].stats[0].rebounds}}r</div>
                    </v-col>
                    <v-col cols=6 class="mx-0 px-0 my-0 py-0" align="center">
                        <div>{{matchStats.team2.players.filter(element => element.jersey === player.jersey)[0].stats[0].assists}}a</div>
                        <div>{{matchStats.team2.players.filter(element => element.jersey === player.jersey)[0].stats[0].personalFouls}}f</div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog
            v-model="playersDialog"
            width="100%"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar app width="100%" height="100px" style=" position: sticky; top: 0; z-index: 1000000000; color: white;" :style="currentTeam === 1 ? 'background-color: #cd2021;' : 'background-color: #1976d2;'">
                    <v-row align=center justify="center">
                        <v-col cols=12 class="text-h4" align="center">
                            {{ `Now playing: ${currentTeam == 1? team1SelectedPlayers.length : team2SelectedPlayers.length}` }}
                        </v-col>
                        <v-btn
                            icon
                            @click="playersDialog = false;"
                            large
                            style="position: absolute; top:0; right:0;"
                            color="white"
                        >
                            <v-icon> mdi-close </v-icon>
                        </v-btn>
                    </v-row>
                </v-toolbar>
                <v-row
                    align="center"
                    justify="center"
                    class="px-5 pb-5 pt-5"
                    style="cursor: pointer;"
                >
                    <v-col
                        cols=12
                        align="start"
                        v-for="player in currentTeam == 1? team1Players : team2Players"
                        :key="player.id"
                    >
                        <v-avatar size="100px" left color="grey">
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon color="white" v-else large> mdi-account </v-icon>
                        </v-avatar>
                        {{ `#${player.jersey} ${player.surname}` }}
                        <v-btn
                            v-if="currentTeam == 1? team1SelectedPlayers.find(element => player.id === element.id) : team2SelectedPlayers.find(element => player.id === element.id)"
                            icon
                            color="red"
                            class="mx-2"
                            @click="removePlayer(player)"
                        >
                            <v-icon>
                                mdi-arrow-left
                            </v-icon>
                            out
                        </v-btn>
                        <v-btn
                            v-else
                            icon
                            color="green"
                            class="mx-2"
                            @click="addPlayer(player)"
                        >
                            in
                            <v-icon >
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="playersDialog = false;"
                    >
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog"
            width="100%"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar app width="100%" height="100px" style=" position: sticky; top: 0; z-index: 1000000000; color: white;" :style="currentTeam === 1 ? 'background-color: #cd2021;' : 'background-color: #1976d2;'">
                    <v-row align=center justify="center">
                        <v-col v-if="statDialog" cols=12 class="text-h4" align=center>
                            {{ currentTeam === 1 ? match.team1.name : match.team2.name}}
                        </v-col>
                        <v-col v-else cols=12 class="text-h4" align=center>
                            {{ getCategoryText(category) }}
                        </v-col>
                        <v-btn
                            icon
                            large
                            @click="dialog = false; choice = null; pointsDialog = false; foulsDialog = false; reboundDialog = false; category = null"
                            style="position: absolute; top:0; right:0;"
                            color="white"
                        >
                            <v-icon> mdi-close </v-icon>
                        </v-btn>
                    </v-row>
                </v-toolbar>
                <v-row v-if="statDialog" class="px-5 py-5 mx-0" align=center justify=center>
                    <v-col cols="12">
                        <v-row class="mx-0 px-0 pt-5" align="center" justify=center>
                            <v-col cols=4 align="center">
                                <v-btn width="95%" height="70px" large fab dark rounded :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=true; pointsDialog = true; category = '2pts'; statDialog = false;">2pt</v-btn>
                            </v-col>
                            <v-col cols=4 align="center">
                                <v-btn width="95%" height="70px" large fab dark rounded :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=true; pointsDialog = true; category = '3pts'; statDialog = false;">3pt</v-btn>
                            </v-col>
                            <v-col cols=4 align="center">
                                <v-btn width="95%" height="70px" large fab dark rounded :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=true; pointsDialog = true; category = '1pt'; statDialog = false;">1pt</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0" align="center">
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'dreb'; statDialog = false; setStat(selectedPlayer);">d. reb</v-btn>
                            </v-col>
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'oreb'; statDialog = false; setStat(selectedPlayer);">o. reb</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0" align="center">
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'to'; statDialog = false; setStat(selectedPlayer);">to</v-btn>
                            </v-col>
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'stl'; statDialog = false; setStat(selectedPlayer);">stl</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0" align="center">
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'asst'; statDialog = false; setStat(selectedPlayer);">asst</v-btn>
                            </v-col>
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=false; category = 'blk'; statDialog = false; setStat(selectedPlayer);">blk</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0" align="center">
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="playersDialog=true; statDialog = false; dialog=false; removePlayer(selectedPlayer);">sub</v-btn>
                            </v-col>
                            <v-col cols=6 align="center">
                                <v-btn width="95%" height="70px" large class="rounded-lg" dark :color="currentTeam === 1 ? '#cd2021' : '#1976d2'" @click="dialog=true; foulsDialog = true; category = 'foul'; statDialog = false;">foul</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-else-if="reboundDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            dark
                            @click="category = 'oreb'; reboundDialog = false;"
                        >
                            Offensive Rebound
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            dark
                            @click="category = 'dreb'; reboundDialog = false; currentTeam = currentTeam === 1 ? 2 : 1;"
                        >
                            Defensive Rebound
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="choice === null && pointsDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            dark
                            @click="setChoice('made')"
                        >
                            <v-icon color="success"> mdi-check </v-icon>
                            made
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            dark
                            @click="setChoice('missed')"
                        >
                            <v-icon color="error"> mdi-close </v-icon>
                            missed
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="choice === null && foulsDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="warning"
                            dark
                            @click="setChoice('personal')"
                        >
                            personal
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="error"
                            dark
                            @click="setChoice('technical')"
                        >
                            technical
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    v-else
                    align="center"
                    justify="center"
                    class="px-5 pb-5 pt-5"
                    style="cursor: pointer;"
                >
                    <v-col
                        cols=12
                        align="start"
                        v-for="player in currentTeam == 1? team1SelectedPlayers : team2SelectedPlayers"
                        :key="player.id"
                        @click="setStat(player)"
                    >
                        <v-avatar size="100px" color="grey" left>
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon color="white" v-else large> mdi-account </v-icon>
                        </v-avatar>
                        {{ `#${player.jersey} ${player.surname}` }}
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="dialog = false; choice = null; pointsDialog = false; foulsDialog = false; reboundDialog = false; category = null"
                    >
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="retry"
            width="100%"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-alert
                prominent
                type="error"
            >
                <v-row align="center">
                    <v-col class="grow">
                        Oops something went wrong...
                    </v-col>
                    <v-col class="shrink">
                        <v-btn @click="setStat(selectedPlayer)">Retry</v-btn>
                    </v-col>
                    <v-col class="shrink">
                        <v-btn @click="retry=false; dialog = false; pointsDialog = false; foulsDialog = false; reboundDialog = false; playersDialog = false; overlay = false">Close</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-dialog>
    </v-container>
</template>

<script>
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'
    import teamService from '@/services/team'

    export default {
        data: () => {
            return {
                retry: false,
                statDialog: false,
                deleteDialog: false,
                endMatchDialog: false,
                reboundDialog: false,
                dialog: false,
                playersDialog: false,
                matchDate: null,
                match: null,
                googleApiHost: 'https://storage.googleapis.com',
                paused: false,
                team1Players: [],
                team1SelectedPlayers: [],
                team2Players: [],
                team2SelectedPlayers: [],
                currentTeam: null,
                team1: null,
                team2: null,
                pointsDialog: false,
                foulsDialog: false,
                choice: null,
                category: null,
                timer: null,
                newMinutes: null,
                newSeconds: null,
                timerDialog: false,
                timerInterval: null,
                feed: [],
                logId: null,
                quarter: 1,
                selectedPlayer: null,
                overlay: false,
                matchStats: null,
                created: true,
                stats: [
                    { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                    { text: '2P%', value: 'two_points_percentage', team1: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 }, team2: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 } },
                    { text: '3P%', value: 'three_points_percentage', team1: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 }, team2: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 } },
                    { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 }, team2: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 } },
                    { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                    { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                    { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                    { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                    { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                    { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                    { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                    { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } },
                    { text: 'TFC', value: 'technicalFouls', team1: { player: null, technicalFouls: 0 }, team2: { player: null, technicalFouls: 0 } }
                ]
            }
        },
        watch: {
            match() {
                this.getFeed()
                this.getMatchStats()
            },
            timer() {
                if (this.timer === 0 && this.quarter <= 10) {
                    this.quarter = this.quarter + 1
                }
            },
            quarter() {
                if (this.quarter <= 4) {
                    if (!this.created) {
                        this.newMinutes = 10
                        this.newSeconds = 0
                        this.changeTimer()
                        this.switchTimer()
                    }
                } else {
                    if (!this.created) {
                        this.newMinutes = 5
                        this.newSeconds = 0
                        this.changeTimer()
                        this.switchTimer()
                    }
                }
                this.created = false
            }
        },
        computed: {
            minutes() {
                let temp = Math.floor(this.timer / 60000)
                return (temp < 10 ? '0' : '') + temp
            },
            seconds() {
                let temp = Math.floor(((this.timer % 60000) / 1000))
                return (temp < 10 ? '0' : '') + temp
            }
        },
        beforeCreate() {
        },
        created() {
            this.timer = 600000
            this.getMatch().then(() => {
                this.getActivePlayers()
            })
        },
        methods: {
            getMatchValues(matchStats) {
                this.stats = [
                    { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                    { text: '2P%', value: 'two_points_percentage', team1: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 }, team2: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 } },
                    { text: '3P%', value: 'three_points_percentage', team1: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 }, team2: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 } },
                    { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 }, team2: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 } },
                    { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                    { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                    { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                    { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                    { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                    { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                    { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                    { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } },
                    { text: 'TFC', value: 'technicalFouls', team1: { player: null, technicalFouls: 0 }, team2: { player: null, technicalFouls: 0 } }
                ]
                this.stats.forEach((stat) => {
                    matchStats.team1.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            if (stat.value === 'two_points_percentage') {
                                stat.team1.twoPointAttempts += player.stats[0].twoPointAttempts
                                stat.team1.twoPointsMade += player.stats[0].twoPointsMade
                            } else if (stat.value === 'three_points_percentage') {
                                stat.team1.threePointAttempts += player.stats[0].threePointAttempts
                                stat.team1.threePointsMade += player.stats[0].threePointsMade
                            } else if (stat.value === 'free_throw_percentage') {
                                stat.team1.freeThrowAttempts += player.stats[0].freeThrowAttempts
                                stat.team1.freeThrowsMade += player.stats[0].freeThrowsMade
                            } else {
                                stat.team1[stat.value] += player.stats[0][stat.value]
                            }
                        }
                    })
                    stat.team1.two_points_percentage = (stat.team1.twoPointsMade / (stat.team1.twoPointAttempts ? stat.team1.twoPointAttempts : 1) * 100).toFixed(0)
                    stat.team1.three_points_percentage = (stat.team1.threePointsMade / (stat.team1.threePointAttempts ? stat.team1.threePointAttempts : 1) * 100).toFixed(0)
                    stat.team1.free_throw_percentage = (stat.team1.freeThrowsMade / (stat.team1.freeThrowAttempts ? stat.team1.freeThrowAttempts : 1) * 100).toFixed(0)
                    matchStats.team2.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            if (stat.value === 'two_points_percentage') {
                                stat.team2.twoPointAttempts += player.stats[0].twoPointAttempts
                                stat.team2.twoPointsMade += player.stats[0].twoPointsMade
                            } else if (stat.value === 'three_points_percentage') {
                                stat.team2.threePointAttempts += player.stats[0].threePointAttempts
                                stat.team2.threePointsMade += player.stats[0].threePointsMade
                            } else if (stat.value === 'free_throw_percentage') {
                                stat.team2.freeThrowAttempts += player.stats[0].freeThrowAttempts
                                stat.team2.freeThrowsMade += player.stats[0].freeThrowsMade
                            } else {
                                stat.team2[stat.value] += player.stats[0][stat.value]
                            }
                        }
                    })
                    stat.team2.two_points_percentage = (stat.team2.twoPointsMade / (stat.team2.twoPointAttempts ? stat.team2.twoPointAttempts : 1) * 100).toFixed(0)
                    stat.team2.three_points_percentage = (stat.team2.threePointsMade / (stat.team2.threePointAttempts ? stat.team2.threePointAttempts : 1) * 100).toFixed(0)
                    stat.team2.free_throw_percentage = (stat.team2.freeThrowsMade / (stat.team2.freeThrowAttempts ? stat.team2.freeThrowAttempts : 1) * 100).toFixed(0)
                })
            },
            getMatchStats() {
                matchService.getMatchStats(this.$route.params.matchId).then(resp => {
                    this.matchStats = resp.data
                    this.getMatchValues(resp.data)
                })
            },
            getCategoryText(category) {
                switch (category) {
                case '1pt':
                    return 'POINTS: 1'
                case '2pts':
                    return 'POINTS: 2'
                case '3pts':
                    return 'POINTS: 3'
                case 'dreb':
                    return 'DEFENSIVE REBOUND'
                case 'oreb':
                    return 'OFFENSIVE REBOUND'
                case 'to':
                    return 'TURNOVER'
                case 'stl':
                    return 'STEALS'
                case 'asst':
                    return 'ASSISTS'
                case 'blk':
                    return 'BLOCKS'
                case 'foul':
                    if (this.choice === 'personal') {
                        return 'PERSONAL FOUL'
                    } else {
                        return 'TECHNICAL FOUL'
                    }
                case 'sub-in':
                    return 'SUBSTITUTE IN ->'
                case 'sub-out':
                    return 'SUBSTITUTE OUT <-'
                default:
                    break
                }
            },
            sortPlayers(players) {
                if (players.length > 0) {
                    return players.sort((a, b) => {
                        if (a.jersey < b.jersey) {
                            return -1
                        }
                        if (a.jersey > b.jersey) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    return []
                }
            },
            setChoice(choice) {
                this.choice = choice
                if (this.selectedPlayer) {
                    this.setStat(this.selectedPlayer)
                }
            },
            endMatch() {
                let data = {
                    content: `${this.minutes === '10' ? (this.quarter - 1) * 10 : (10 - this.minutes) + (this.quarter - 1) * 10}:${(this.seconds === '00' ? '00' : (60 - this.seconds) < 10 ? '0' + (60 - this.seconds) : (60 - this.seconds))}`
                }
                matchService.endMatch(this.$route.params.matchId, data).then(resp => {
                    console.log(resp.data)
                    this.endMatchDialog = false
                    this.$router.go(-1)
                })
            },
            addPlayer(player) {
                if (this.currentTeam === 1 ? this.team1SelectedPlayers.length < 5 : this.team2SelectedPlayers.length < 5) {
                    this.selectedPlayer = player
                    this.currentTeam === 1 ? this.team1SelectedPlayers.push(player) : this.team2SelectedPlayers.push(player)
                    if (this.currentTeam === 1) {
                        this.sortPlayers(this.team1SelectedPlayers)
                    } else {
                        this.sortPlayers(this.team2SelectedPlayers)
                    }
                }
                this.category = 'sub-in'
                this.setStat(player)
            },
            removePlayer(player) {
                this.selectedPlayer = player
                this.currentTeam === 1 ? this.team1SelectedPlayers = this.team1SelectedPlayers.filter(element => player.id !== element.id) : this.team2SelectedPlayers = this.team2SelectedPlayers.filter(element => player.id !== element.id)
                if (this.currentTeam === 1) {
                    this.sortPlayers(this.team1SelectedPlayers)
                } else {
                    this.sortPlayers(this.team2SelectedPlayers)
                }
                this.category = 'sub-out'
                this.setStat(player)
            },
            getTeamLogo(log) {
                return this.googleApiHost + '/' + (this.match.team1.id === log.teamId ? this.match.team1.logo : this.match.team2.logo)
            },
            getMatch() {
                return matchService.getMatch(this.$route.params.matchId).then(resp => {
                    this.match = resp.data
                    if (this.match.matchDatetime === null) {
                        this.matchDate = null
                        this.matchTime = null
                    } else {
                        this.matchDate = momentTimezone(this.match.matchDatetime).tz('Europe/Athens').locale('el-GR').format('dddd D/M/y, HH:mm').toString()
                    }
                    this.team1 = resp.data.team1.id
                    this.team2 = resp.data.team2.id
                    this.fetchFilteredTeam1Players(resp.data.team1.id)
                    this.fetchFilteredTeam2Players(resp.data.team2.id)
                })
            },
            getActivePlayers() {
                this.team1SelectedPlayers = []
                this.team2SelectedPlayers = []
                matchService.getActivePlayers(this.$route.params.matchId).then(resp => {
                    resp.data.team1Players.forEach(element => {
                        this.team1SelectedPlayers.push(this.team1Players.filter(player => player.id === element.playerId)[0])
                        this.sortPlayers(this.team1SelectedPlayers)
                    })
                    resp.data.team2Players.forEach(element => {
                        this.team2SelectedPlayers.push(this.team2Players.filter(player => player.id === element.playerId)[0])
                        this.sortPlayers(this.team2SelectedPlayers)
                    })
                })
                this.sortPlayers(this.team1SelectedPlayers)
                this.sortPlayers(this.team2SelectedPlayers)
            },
            getLogText(log) {
                let temp = ''
                Object.keys(log).forEach(key => {
                    if (key === 'defensiveRebounds') {
                        temp += 'defensive rebound'
                    } else if (key === 'offensiveRebounds') {
                        temp += 'offensive rebound'
                    } else if (key === 'turnovers') {
                        temp += 'turnover'
                    } else if (key === 'turnovers') {
                        temp += 'turnover'
                    } else if (key === 'steals') {
                        temp += 'steal'
                    } else if (key === 'assists') {
                        temp += 'assist'
                    } else if (key === 'playerMatchStatus' && log[key] === 'begin') {
                        temp += 'subbed in'
                    } else if (key === 'playerMatchStatus' && log[key] === 'stop') {
                        temp += 'subbed out'
                    } else if (key === 'blockedShots') {
                        temp += 'block'
                    } else if (key === 'personalFouls') {
                        temp += 'personal foul'
                    } else if (key === 'technicalFouls') {
                        temp += 'technical foul'
                    } else if (key === 'freeThrowsMade' && log[key] === 1) {
                        temp += 'free throw made'
                    } else if (key === 'freeThrowAttempts' && log['freeThrowsMade'] === 0) {
                        temp += 'free throw missed'
                    } else if (key === 'twoPointsMade' && log[key] === 1) {
                        temp += '2pt made'
                    } else if (key === 'twoPointAttempts' && log['twoPointsMade'] === 0) {
                        temp += '2pt missed'
                    } else if (key === 'threePointsMade' && log[key] === 1) {
                        temp += '3pt made'
                    } else if (key === 'threePointAttempts' && log['threePointsMade'] === 0) {
                        temp += '3pt missed'
                    }
                })
                return `#${log.jersey} ${temp}`
            },
            getFeed() {
                matchService.getMatchLogs(this.$route.params.matchId).then((resp) => {
                    this.feed = resp.data
                        .sort((a, b) => {
                            let timestampA = a.timestamp.split(':')
                            let timestampB = b.timestamp.split(':')
                            let msecA = parseInt(timestampA[0]) * 60000 + parseInt(timestampA[1]) * 1000
                            let msecB = parseInt(timestampB[0]) * 60000 + parseInt(timestampB[1]) * 1000
                            if (msecA < msecB) {
                                return 1
                            }
                            if (msecA > msecB) {
                                return -1
                            }
                            return 0
                        })
                    if (this.created) {
                        this.quarter = parseInt((parseInt(this.feed[0].timestamp.split(':')[0])) / 10) + 1
                        let seconds = (60 - parseInt(this.feed[0].timestamp.split(':')[1])) * 1000
                        let minutes = ((parseInt(this.feed[0].timestamp.split(':')[0]) % 10 === 0 && parseInt(this.feed[0].timestamp.split(':')[1])) === 0 ? 10 : (parseInt(this.feed[0].timestamp.split(':')[0]) % 10 === 0 && parseInt(this.feed[0].timestamp.split(':')[1])) !== 0 ? 9 : (parseInt(this.feed[0].timestamp.split(':')[0]) % 10)) * 60000
                        this.timer = minutes + seconds
                        console.log(this.timer)
                    }
                })
            },
            deleteLog() {
                let log = this.feed.filter((element) => this.logId === element.logId)[0]
                matchService.deleteMatchLog(this.match.id, this.logId).then((resp) => {
                    this.deleteDialog = false
                    this.feed = resp.data
                    if (log.playerMatchStatus === 'stop' || log.playerMatchStatus === 'begin') {
                        this.getActivePlayers()
                    }
                    this.getMatch()
                })
            },
            setStat(player) {
                this.retry = false
                this.overlay = true
                let data = {
                    teamId: this.currentTeam === 1 ? this.team1 : this.team2,
                    playerId: player.id,
                    jersey: player.jersey,
                    timestamp: `${parseInt(this.minutes) === 10 ? (this.quarter - 1) * 10 : (9 - parseInt(this.minutes)) + (this.quarter - 1) * 10}:${(this.seconds === '00' ? '00' : (60 - this.seconds) < 10 ? '0' + (60 - this.seconds) : (60 - this.seconds))}`
                }
                switch (this.category) {
                case '1pt':
                    if (this.choice === 'made') {
                        data.freeThrowsMade = 1
                        data.freeThrowAttempts = 1
                    } else {
                        data.freeThrowAttempts = 1
                    }
                    break
                case '2pts':
                    if (this.choice === 'made') {
                        data.twoPointsMade = 1
                        data.twoPointAttempts = 1
                    } else {
                        data.twoPointAttempts = 1
                    }
                    break
                case '3pts':
                    if (this.choice === 'made') {
                        data.threePointsMade = 1
                        data.threePointAttempts = 1
                    } else {
                        data.threePointAttempts = 1
                    }
                    break
                case 'dreb':
                    data.defensiveRebounds = 1
                    break
                case 'oreb':
                    data.offensiveRebounds = 1
                    break
                case 'to':
                    data.turnovers = 1
                    break
                case 'stl':
                    data.steals = 1
                    break
                case 'asst':
                    data.assists = 1
                    break
                case 'blk':
                    data.blockedShots = 1
                    break
                case 'foul':
                    if (this.choice === 'personal') {
                        data.personalFouls = 1
                    } else {
                        data.technicalFouls = 1
                    }
                    break
                case 'sub-in':
                    data.playerMatchStatus = 'begin'
                    break
                case 'sub-out':
                    data.playerMatchStatus = 'stop'
                    break
                default:
                    break
                }
                matchService.importMatchLog(this.match.id, data).then((resp) => {
                    if ((this.category === '2pts' || this.category === '3pts') && this.choice === 'made') {
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.reboundDialog = false
                        this.dialog = false
                        setTimeout(() => {
                            this.overlay = false
                            this.dialog = true
                            this.category = 'asst'
                        }, '500')
                    } else if ((this.category === '2pts' || this.category === '3pts' || this.category === '1pt') && this.choice === 'missed') {
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.dialog = false
                        setTimeout(() => {
                            this.overlay = false
                            this.dialog = true
                            this.reboundDialog = true
                        }, '500')
                    } else if (this.category === 'stl') {
                        this.currentTeam = this.currentTeam === 1 ? 2 : 1
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.reboundDialog = false
                        this.dialog = false
                        setTimeout(() => {
                            this.dialog = true
                            this.overlay = false
                            this.category = 'to'
                        }, '500')
                    } else if (this.category === 'foul') {
                        setTimeout(() => {
                            this.overlay = false
                        }, '500')
                        this.dialog = false
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.category = null
                        this.choice = null
                        this.reboundDialog = false
                        this.switchTimer()
                    } else {
                        this.dialog = false
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.category = null
                        this.choice = null
                        this.reboundDialog = false
                        setTimeout(() => {
                            this.overlay = false
                        }, '500')
                    }
                    this.match = resp.data
                    this.selectedPlayer = null
                }).catch(() => {
                    this.dialog = false
                    this.pointsDialog = false
                    this.foulsDialog = false
                    this.reboundDialog = false
                    this.playersDialog = false
                    this.overlay = false
                    this.retry = true
                })
            },
            fetchFilteredTeam1Players(id) {
                this.loading = true
                teamService.getTeam(id).then(resp => {
                    this.team1Players = resp.data.players.filter(element => element.jersey !== null).sort((a, b) => {
                        if (a.jersey < b.jersey) {
                            return -1
                        }
                        if (a.jersey > b.jersey) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
            },
            fetchFilteredTeam2Players(id) {
                this.loading = true
                teamService.getTeam(id).then(resp => {
                    this.team2Players = resp.data.players.filter(element => element.jersey !== null).sort((a, b) => {
                        if (a.jersey < b.jersey) {
                            return -1
                        }
                        if (a.jersey > b.jersey) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
            },
            getPlayerText(item) {
                return `#${item.jersey} ${item.name} ${item.surname}`
            },
            // openFullscreen() {
            //     var elem = document.getElementById('stats')
            //     console.log(elem)
            //     if (elem.requestFullscreen) {
            //         elem.requestFullscreen()
            //     } else if (elem.webkitRequestFullscreen) { /* Safari */
            //         elem.webkitRequestFullscreen()
            //     } else if (elem.msRequestFullscreen) { /* IE11 */
            //         elem.msRequestFullscreen()
            //     }
            // },
            switchTimer() {
                this.paused = !this.paused
                if (!this.paused) {
                    clearInterval(this.timerInterval)
                } else if (this.timer > 0) {
                    this.timerInterval = setInterval(() => {
                        this.timer = this.timer - 1000
                    }, 1000)
                }
            },
            changeTimer() {
                this.timer = parseInt(this.newMinutes) * 60000 + parseInt(this.newSeconds) * 1000
            }
        }
    }
</script>

<style scoped>
    .match-timer{
        font-weight: 700;
        border: 2px solid black;
        border-radius: 10px;
    }

    .match-timer-danger{
        border-color: red !important;
        color: red !important;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); /* Black see-through */
        color: #f1f1f1;
        width: 100%;
        transition: .5s ease;
        opacity:0;
        color: white;
        font-size: 20px;
        padding: 20px;
        text-align: center;
    }
</style>
